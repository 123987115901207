import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // 打包时打开
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),  // 开发时打开
  // history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
      meta: {

        title: '浩博泰德（北京）科技有限公司',
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/About.vue'),
      meta: {
        title: '关于浩博泰德',
      }
    }, {
      path: '/culture',
      name: 'culture',
      component: () => import('@/views/Culture.vue'),
      meta: {
        title: '浩博泰德企业文化',
      }
    }, {
      path: '/solutions',
      name: 'solutions',
      component: () => import('@/views/Solutions.vue'),
      meta: {
        title: '浩博泰德 — 解决方案',
      }
    }, {
      path: '/elements',
      name: 'elements',
      component: () => import('@/views/Elements.vue'),
      meta: {
        title: '浩博泰德 - 场景要素',
      }
    }, {
      path: '/services',
      name: 'services',
      component: () => import('@/views/Services.vue'),
      meta: {
        title: '浩博泰德 - 系统服务',
      }
    }, {
      path: '/cases',
      name: 'cases',
      component: () => import('@/views/Cases.vue'),
      meta: {
        title: '浩博泰德 - 项目案例',
      }
    }, {
      path: '/iot',
      name: 'iot',
      component: () => import('@/views/Iot.vue'),
      meta: {
        title: '浩博泰德 - 物联智控',
      }
    }, {
      path: '/autonomousf',
      name: 'autonomousf',
      component: () => import('@/views/Autonomousf.vue'),
      meta: {
        title: '浩博泰德 - 自主可控',
      }
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/404.vue'),
      meta: {
        title: '404',
      }
    }
  ]
})

export default router
