import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'



import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css'

//跳页时从顶端开始
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})
const app = createApp(App)

// ============  动态修改页面 title ============
const defaultTitle = 'HopetideCMS'
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : defaultTitle
    next()
})

app.use(ElementPlus).use(store).use(router).mount('#app') 
