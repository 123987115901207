<template>
  <div class="leftNav ">
    <div class="leftNavBox">
      <div class="logoBg">
        <div class="leftTop">
          <router-link to="/">
            <img src="image/toplogo.svg" alt="" />
          </router-link>
        </div>
        <!---移动端导航开关--->
        <div class="miniOpen">
          <div class="nav_btn " @click="toggleDrawerMini" :class="drawerVisibleMini ? 'active' : ''">
            <span class="top"></span>
            <span class="middle"></span>
            <span class="bottom"></span>
          </div>
        </div>
        <!---PC端导航开关--->
        <div class="open">
          <div class="nav_btn " @click="toggleDrawer" :class="drawerVisible ? 'active' : ''">
            <span class="top"></span>
            <span class="middle"></span>
            <span class="bottom"></span>
          </div>
        </div>

        <div class="bottom">
          <!-- <img src="image/navbottom.svg" alt=""> -->
        </div>

      </div>
    </div>
    <!---移动端导航--->
    <el-drawer v-model="drawerVisibleMini" :show-close="false" :destroy-on-close="true" size="60%" direction="ttb"
      :with-header="false">
      <div class="drawerMiniBg">
        <img class="w-100 fit-cover" src="image/navBg.jpg" alt="">
      </div>
      <div class="drawerMiniBox">
        <div class="navBox">
          <el-menu default-active="2" class="el-menu-vertical-demo" :router="true" :menu-active-class="true"
            background-color="rgba(0, 0, 0, 0)" @open="handleOpen" @close="handleClose">
            <el-menu-item class="mrff" v-for="item in miniMenuItems" :key="item.name" :index="item.path"
              @click="drawerVisibleMini = false">
              {{ item.name }}
            </el-menu-item>
            <el-menu-item index="/about" @click="drawerVisibleMini = false">
              企业简介
            </el-menu-item>
          </el-menu>
        </div>

      </div>
    </el-drawer>
    <!---PC导航--->
    <el-drawer v-model="drawerVisible" :show-close="false" :destroy-on-close="true" size="60%" direction="ltr"
      :with-header="false">
      <div class="drawerBg">
        <img class="w-100 fit-cover" src="image/navBg.jpg" alt="">
      </div>
      <div class="drawerBox">
        <div class="navBox">

          <el-menu default-active="2" class="el-menu-vertical-demo" :router="true" :menu-active-class="true"
            background-color="rgba(0, 0, 0, 0)" @open="handleOpen" @close="handleClose">
            <el-menu-item v-for="item in menuItems" :key="item.name" :index="item.path" @click="drawerVisible = false">
              <a class="text-dark" :href="item.id">{{ item.name }}</a>
              <!-- {{ item.name }} -->
            </el-menu-item>
            <el-menu-item index="/about" @click="drawerVisible = false">
              企业简介
            </el-menu-item>
          </el-menu>


        </div>
        <div class="ifnBox">
          <div class="py-4 px-3">
            <h6 class="mb-1 pl-2">我们的客户</h6>
            <small class="text-muted opacity-25 din_alternatebold pl-2  ">Our customers</small>
            <div class="row addLogos pl-1 pt-3 mb-5">
              <div class="col-4 mb-1 pl-0 pr-1" v-for="(item, index) in customItems" :key="index">
                <div class="addLogoBg px-3 py-2">
                  <img :src="'image/addlogo/' + item.logoImg" alt="">
                </div>
              </div>
            </div>


            <h6 class="mb-0 pl-2">生态合作伙伴</h6>
            <small class="text-muted opacity-25 din_alternatebold pl-2  ">Ecological partners</small>
            <div class="row addLogos pl-1 pt-3">
              <div class="col-4 mb-1 pl-0 pr-1" v-for="(item, index) in addItems" :key="index">
                <div class="addLogoBg px-3 py-2">
                  <img :src="'image/addlogo/' + item.logoImg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </el-drawer>
  </div>
</template>

<script >
export default {
  name: "leftNav",
  components: {},
  // 创建路由器对象
  props: {
    sideMenulist: {
      type: Array,
      required: true,
    },
    selectId: {
      type: String,
    }
  },
  data() {
    return {
      activeIndex: '',
      drawerVisible: false,
      drawerVisibleMini: false,
      menuItems: [
        { name: "首页", path: "/", id: "/" },
        { name: "解决方案", path: "/", id: "/#solutions" },
        { name: "场景要素", path: "/", id: "/#elements" },
        { name: "系统服务", path: "/", id: "/#Services" },
        { name: "项目案例", path: "/", id: "/#Cases" },
        { name: "物联智控", path: "/", id: "/#iot" },
        { name: "自主可控", path: "/", id: "/#autonomous" },
        { name: "联系我们", path: "/", id: "/#footer" },
      ],
      miniMenuItems: [
        { name: "首页", path: "/", id: "/" },
        { name: "解决方案", path: "solutions", id: "/#solutions" },
        { name: "场景要素", path: "elements", id: "/#elements" },
        { name: "系统服务", path: "services", id: "/#Services" },
        { name: "项目案例", path: "cases", id: "/#Cases" },
        { name: "物联智控", path: "iot", id: "/#iot" },
        { name: "自主可控", path: "autonomousf", id: "/#autonomous" },
        { name: "联系我们", path: "footer", id: "/#footer" },
      ],

      addItems: [
        {
          logoImg: "sugon.svg",
        }, {
          logoImg: "caos.svg",
        }, {
          logoImg: "GreaWall.svg",
        }, {
          logoImg: "hanwang.svg",
        }, {
          logoImg: "huawei.svg",
        }, {
          logoImg: "iflytek.svg",
        }, {
          logoImg: "lenovo.svg",
        }, {
          logoImg: "wps.svg",
        }, {
          logoImg: "venus.svg",
        }, {
          logoImg: "Kylinsoft.svg",
        }, {
          logoImg: "h3c.svg",
        },
        {
          logoImg: "hikvision.svg",
        },
        {
          logoImg: "topsec.svg",
        }, {
          logoImg: "inspur.svg",
        }, {
          logoImg: "ruijie.svg",
        }
      ],
      customItems: [
        {
          logoImg: "caos.svg",
        }, {
          logoImg: "cae.svg",
        }, {
          logoImg: "cass.svg",
        }, {
          logoImg: "caas.svg",
        }, {
          logoImg: "ucas.svg",
        }, {
          logoImg: "tu.svg",
        }, {
          logoImg: "bbu.svg",
        }, {
          logoImg: "biot.svg",
        }, {
          logoImg: "pu.svg",
        },
      ]
      // valueItems: [
      //   {
      //     vOne: "高品质产品，",
      //     vTwo: "不低价不拼价",
      //   }, {
      //     vOne: "自己擅长的，",
      //     vTwo: "不虚张不夸大",
      //   }, {
      //     vOne: "最合理利润，",
      //     vTwo: "不假货不暴利",
      //   }, {
      //     vOne: "最高效交付，",
      //     vTwo: "不拖沓不应付",
      //   }, {
      //     vOne: "最领先技术，",
      //     vTwo: "不落后不将就",
      //   }
      // ]

    };
  },
  watch: {
    selectId() {
      this.activeIndex = this.selectId;
    }
  },
  methods: {
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible; // 切换抽屉的显示状态
    },
    toggleDrawerMini() {
      this.drawerVisibleMini = !this.drawerVisibleMini; // 切换抽屉的显示状态
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    // handleMenuSelect(index) {
    //   // 根据索引获取对应的路由地址
    //   const route = this.$router.options.routes[item.path];

    //   if (route && route.name === '/') {
    //     // 如果目标路由名称为'anchor'（表示有锚点），则将其作为新的URL hash值
    //     window.location.href = '#' + item.id;

    //     return;
    //   }

    //   // 否则按默认方式进行路由切换
    //   this.$router.push({ path: item.path });
    // },


  }




};
</script>

<style lang="scss">
.leftNav {

  .leftNavBox {
    position: fixed;
    width: 80px;
    z-index: 9999;
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    left: 0;
    height: 100vh;

    .logoBg {
      display: flex;
      max-width: 80px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      z-index: 9999;

      img {
        width: 100%;
      }

      .leftTop {
        padding: 20px 16px;
      }

      .miniOpen,
      .open {
        position: relative;

        .nav_btn {
          width: 30px;
          height: 32px;
          position: absolute;
          left: -14px;
          // top: 46%;
          margin-top: -40px;
          overflow: hidden;
          cursor: pointer;

          span {
            display: block;
            width: 100%;
            height: 2px;
            background-color: #007E3C;
            position: absolute;
            top: 50%;
            left: 0;
            transform-origin: left 50%;
            border-radius: 1px;
            -webkit-transition: all 0.47s ease;
            -moz-transition: all 0.47s ease;
            -ms-transition: all 0.47s ease;
            transition: all 0.47s ease;
          }

          span.top {
            margin-top: -8px;

          }

          span.middle {}

          span.bottom {
            margin-top: 8px;

          }
        }

        .menu {
          position: absolute;
          top: -10px;
          left: -16px;
          font-size: 0.8rem;
          text-align: center;
          color: rgba(0, 0, 0, 0.3);
          font-family: din_alternatebold;
        }

        .nav_btn.active {
          span.top {
            -webkit-transform: rotate(45deg) translate(0, -4px);
            -moz-transform: rotate(45deg) translate(0, -4px);
            -ms-transform: rotate(45deg) translate(0, -4px);
            transform: rotate(45deg) translate(0, -4px);
          }

          span.middle {
            opacity: 0;
          }

          span.bottom {
            -webkit-transform: rotate(-45deg) translate(0, 4px);
            -moz-transform: rotate(-45deg) translate(0, 4px);
            -ms-transform: rotate(-45deg) translate(0, 4px);
            transform: rotate(-45deg) translate(0, 4px);
          }
        }


      }

      .bottom {
        padding: 0px;
      }
    }

    .miniOpen {
      display: none;
    }
  }

  .el-drawer {
    background-color: rgba($color: #ffffff, $alpha: 0.96);

    .el-drawer__body {
      padding: 0 !important;

      .drawerBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 0;

        img {
          width: 100%;
          height: 100vh;
        }
      }

      .drawerMiniBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 0;

        img {
          width: 100%;
          height: 100vh;
        }
      }

      .drawerMiniBox,
      .drawerBox {
        position: relative;
        z-index: 100;
        padding-left: 100px;
        display: flex;
        height: 100%;

        .navBox {
          width: calc(100% - 400px);
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 80px;

          .el-menu {
            border-right: 0px solid #ffffff;

            .el-menu-item:nth-last-child(2) {
              display: none;
            }

            .el-menu-item,
            .el-sub-menu__title {
              font-size: 1.1rem;
              border-radius: 6px
            }

            .el-menu-item.is-active {
              color: #007E3C;
            }

            .el-menu-item:hover,
            .el-sub-menu:hover .el-sub-menu__title {
              background-color: rgba(0, 127, 225, 0.18) !important;
            }
          }


        }

        .ifnBox {
          width: 400px;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.6);
          display: flex;
          align-items: end;

          .addLogos {
            margin: 0;

            .addLogoBg {
              background-color: rgba(255, 255, 255, 0.4);

              img {
                width: 100%;
              }
            }

          }
        }

      }

      .drawerMiniBox {
        padding-left: 0;
      }
    }

    .el-overlay {
      background-color: rgba(0, 0, 0, 0.1)
    }
  }

  .el-drawer.ttb {
    height: 80vh !important;
    padding-top: 80px;
  }
}



@media (max-width: 720px) {
  .leftNav {
    .leftNavBox {
      width: 100%;
      height: 80px;

      .logoBg {
        width: 100%;
        max-width: 100vw;
        align-items: baseline;
        flex-direction: row;
        padding: 0;

        .leftTop {
          width: 80px;
        }

        .open {
          padding: 10px;
          display: none;


        }

        .miniOpen {
          display: block;
          padding: 10px;

          .nav_btn {
            left: -2rem;
            margin-top: -16px;
          }
        }

        .bottom {
          display: none;
        }
      }
    }
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@keyframes scroll1 {

  0%,
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@keyframes scale {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}


@media screen and (min-width: 768px) {
  .nav_btn:not(.active):hover span {
    background-color: #a97d55;
  }

  .nav_btn:not(.active):hover span.top {
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }

  .nav_btn:not(.active):hover span.bottom {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
</style>
