<template>
  <div class="footerbox">
    <div class="position-absolute bottom right z-index-100 goTopDiv ">
      <img v-if="btnFlag" class="go-top w-100 " src="@/assets/images/gotop.svg" @click="backTop" />
    </div>
    <div class="container px-0 py-4">
      <div type="flex" class="row ml-0 text-white">
        <!--二维码-->
        <div
          class="col-12 col-md-2 pt-3 pt-md-4 px-2 px-md-0 float-right order-md-3 border-bottom border-dark border-md-0 text-center text-md-left">
          <h6 class="text-white text-left"><img class="w-h-18p" src="@/assets/images/titleLeft04.svg" alt="">关注我们</h6>
          <div class="row  pt-2 ml-0">
            <div class="col-md-12 col-6 offset-3 offset-md-0 px-md-0">
              <img class="w-100 mb-2 imgQr" src="@/assets/images/qr.svg" alt="">
              <p class="textQr ">微信公众号</p>
            </div>
          </div>
        </div>


        <div
          class="col-12 col-md-6 offset-sm-1 pt-3 pt-md-4 px-4 order-md-2 border-bottom border-dark border-md-0 text-center text-md-left">
          <div class="px-2 mb-5 mb-md-2">
            <!-- <div class="pl-5">
              <h6 class="mb-0 pl-2">负责任，有价值</h6>
              <small class="text-muted opacity-25 din_alternatebold">Responsible and valuable</small>
            </div> -->
            <div class="pb-2">
              <img src="image/footerSvg.svg" alt="">
            </div>

            <div class="slogan">
              <p class="text-muted mb-1" v-for="(item, index) in valueItems" :key="index">
                我们坚持只做<span class=" ">{{ item.vOne }} </span> {{ item.vTwo }}
              </p>
            </div>
          </div>
        </div>


        <!--网站基本信息-->
        <div class="col-12 col-md-3  pt-3 pt-md-4 pl-2 pr-4 pb-3  px-md-0 float-left  order-md-1">

          <div class="text-center text-sm-left addiInfo">
            <img class="w-100p mb-3" src="@/assets/images/logow.svg" alt="">
            <p class="mb-1">
              <span class="icon lwhpon_hollow mr-2 opacity-50"></span>
              <span class="din_alternatebold opacity-50">010-60777166 / 60777155</span>
            </p>
            <p class="mb-1">
              <span class="icon lwmeil_hollow mr-2 opacity-50"></span>
              <span class=" opacity-50">hopetide@taide.vip</span>
            </p>
            <p class="mb-2 pb-1">
              <span class="icon lwpositioning mr-2 opacity-50"></span>
              <span class=" opacity-50">北京-昌平区北京农学院科技综合楼A901</span>
            </p>
            <p class=" pl-0 mb-0 opacity-75 l-h-08 pt-2">
              <span class="  aboutContent opacity-75">成就用户，我们坚持与用户一起思考</span>
            </p>
            <p class=" pl-0 mb-3 opacity-25 l-h-1">
              <span class="   ">Achieve users, we insist on thinking with users</span>
            </p>
          </div>
        </div>

      </div>


      <el-divider class="border-dark opacity-50" />

      <p class="copyright small text-secondary px-sm-4 px-md-0 text-center pt-2 ">
        版权 © <span class=" din_alternatebold">2019 ~ 2024</span> 浩博泰德(北京)科技有限公司
        <a href="https://beian.miit.gov.cn/" class="text-white opacity-50" target="_blank"> 京<span
            class=" din_alternatebold">ICP</span>备<span class=" din_alternatebold">20005441</span>号<span
            class=" din_alternatebold">-1</span></a>. 版权所有
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterQnav",
  data() {
    return {
      btnFlag: "",
      seen: false,
      // center: { lng: 0, lat: 0 },
      centerOperationsCenter: { lng: 0, lat: 0 },
      zoom: 10,
      valueItems: [
        {
          vOne: "高品质产品，",
          vTwo: "不低价不拼价",
        }, {
          vOne: "自己擅长的，",
          vTwo: "不虚张不夸大",
        }, {
          vOne: "最合理利润，",
          vTwo: "不假货不暴利",
        }, {
          vOne: "最高效交付，",
          vTwo: "不拖沓不应付",
        }, {
          vOne: "最领先技术，",
          vTwo: "不落后不将就",
        }
      ]
    };
  },

  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  mounted: function () {
    // this.enableScrollWheelZoom(true);  

  },
  methods: {
    handler({ BMap, map }) {
      console.log(BMap, map)
      //行政管理中心是116.477275,39.925373
      // this.center.lng = 116.477275
      // this.center.lat = 39.925373

      //直播运营中心是116.27648,40.106896
      this.centerOperationsCenter.lng = 116.27648
      this.centerOperationsCenter.lat = 40.106896


      this.zoom = this.zoom
    },
    getClickInfo(e) {
      // console.log(e.point.lng)
      // console.log(e.point.lat)
      this.center.lng = e.point.lng
      this.center.lat = e.point.lat
    },
    getClickInfoOC(e) {
      // console.log(e.point.lng)
      // console.log(e.point.lat)
      this.centerOperationsCenter.lng = e.point.lng
      this.centerOperationsCenter.lat = e.point.lat
    }
  },
  methods: {
    visible: function () {
      this.seen = true;
    },
    invisible: function () {
      this.seen = false;
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      let that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.footerbox {
  background-color: #171F30;
  color: #fff;
  position: relative;
  padding-left: 80px;



  .slogan {
    padding-top: 10px;

    p {
      font-size: 12px !important;
      line-height: 1.5;
    }
  }

  .imgQr {
    max-width: 106px !important;
  }

  .textQr {
    font-size: 12px !important;
    opacity: 0.5;
  }

  .addiInfo {
    p {
      font-size: 12px !important;
    }
  }
}

.goTopDiv {
  width: 80px;
  margin-right: 10px;
  margin-bottom: 10px;

}

.dividerMB {
  margin-bottom: 2px;
}

@media (min-width: 768px) {


  .border-md-0 {
    border: 0 !important;
  }
}

@media (max-width: 768px) {
  .footerbox {
    padding-left: 0px !important;

    h6.text-white {
      padding-left: 20px;
    }

    .addiInfo {
      padding-top: 2rem;
    }

    .copyright {
      font-size: 0.6rem !important;
    }
  }
}
</style>
